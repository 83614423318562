import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldLabel.module.scss';


const FieldLabel = (props) => {
  const {
    label,
    required,
    disabled,
    htmlFor,
  } = props;
  const wrapperClasses = [
    styles.wrapper,
    disabled && styles.disabled,
  ].join(' ');

  return (
    <div className={wrapperClasses}>
      <label className={styles.label} htmlFor={htmlFor}>
        {label}
        {required && <span>*</span>}
      </label>
    </div>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

FieldLabel.defaultProps = {
  required: false,
  disabled: false,
};

export default FieldLabel;
