import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldStatusBlock.module.scss';


const FieldStatusBlock = (props) => {
  const {
    displayBlock,
    error,
    touched,
    focused,
    children,
  } = props;

  const statusBlockClasses = [
    displayBlock && styles.statusBlock,
    (focused) && styles.focused,
    (!focused && touched && error) && styles.error,
  ].join(' ');

  return (
    <div className={styles.wrapper}>
      <div className={styles.blockWrapper}>
        <div className={statusBlockClasses}>{children}</div>
      </div>
      {(touched && error) &&
        <div className={styles.errorMessage}>{error}</div>
      }
    </div>
  );
};

FieldStatusBlock.propTypes = {
  error: PropTypes.string,
  touched: PropTypes.bool,
  focused: PropTypes.bool,
  displayBlock: PropTypes.bool,
};

FieldStatusBlock.defaultProps = {
  error: '',
  touched: false,
  focused: false,
  displayBlock: true,
};

export default FieldStatusBlock;
