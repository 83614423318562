import { useStaticQuery, graphql } from 'gatsby';

const testimonialCarouselHook = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulTestimonialCarousel (
      id: {
        eq: "da8873b7-62c5-5009-9014-51c5d772743d",
      },
    ) {
        id
          testimonials {
            id
            name
            quoteLong {
              quoteLong
            }
          }
      }
    }
  `);

  const testimonials = data.contentfulTestimonialCarousel.testimonials.map(testimonial => ({
    key: testimonial.id,
    name: testimonial.name,
    quote: testimonial.quoteLong.quoteLong,
  }));

  return testimonials;
};

export default testimonialCarouselHook;
