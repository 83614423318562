import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { renderTextField } from '../util/formik-custom-fields';
import Button from '../components/Button/Button';

const Contact = ({ light }) => (
  <Formik
    initialValues={{ name: '', email: '', message: '' }}
    validationSchema={yup.object().shape({
      name: yup.string().required('Required'),
      email: yup.string().email().required('Required'),
      message: yup.string().required('Required'),
    })}
    onSubmit={(values, { setSubmitting, setStatus }) => {
      setSubmitting(true);
      const toUrlEncoded = obj => Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: toUrlEncoded({ 'form-name': 'contact', ...values }),
      })
        .then(({ status }) => setTimeout(() => {
          setSubmitting(false);
          if (status !== 200) {
            throw new Error(`${status}: Could not send`);
          }
          setStatus({ formSubmitted: true });
        }, 500))
        .catch((error) => {
          setSubmitting(false);
          setStatus({ formError: error.message });
        });
    }}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      status,
    }) => {
      const fieldIsValid = name => values[name] && !errors[name];
      const hasErrors = !fieldIsValid('name') || !fieldIsValid('email') || !fieldIsValid('message');
      return (
        status && status.formSubmitted
          ? (
            <h3>Thanks for your message!</h3>
          )
          : (
            <form onSubmit={handleSubmit} data-netlify="true" data-netlify-honeypot="bot-field">
              <Field
                component={renderTextField}
                type="text"
                name="name"
                label="Name"
                id="name-field"
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <br />
              <Field
                component={renderTextField}
                type="email"
                name="email"
                label="Email"
                id="email-field"
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              <br />
              <Field
                component={renderTextField}
                name="message"
                label="Message"
                id="message-field"
                disabled={isSubmitting}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                rows={5}
                multiLine
              />
              <br />
              {status && status.formError
                ? (
                  <Fragment>
                    {status.formError}
                    <br />
                  </Fragment>
                )
                : null}
              <br />
              <Button
                type="submit"
                label={isSubmitting ? 'Sending' : 'Get in touch'}
                disabled={hasErrors || isSubmitting}
                cta={!isSubmitting}
              />
            </form>
          )
      );
    }}
  </Formik>
);


Contact.propTypes = { light: PropTypes.bool };

Contact.defaultProps = { light: false };

export default Contact;
