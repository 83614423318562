import { useStaticQuery, graphql } from 'gatsby';

const heroHook = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHero (
        id: {
          eq: "b9123e29-3ebd-5c99-a43e-56231071e0f7",
        },
      ) {
        heading
        subHeading
        background {
          fluid (
            maxWidth: 2000,
          ) {
            srcSet
            aspectRatio
            src
            sizes
            base64
          }
        }
      }
    }
  `);

  return data.contentfulHero;
};

export default heroHook;
