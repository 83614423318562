import { useStaticQuery, graphql } from 'gatsby';

const servicesHook = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulService (
        sort: {
          fields:displayWeight,
          order: DESC,
        }
      ) {
        edges {
          node {
            id
            name
            title
            fields {
              slug
            }
            description {
              description
            }
            gallery {
              fluid (
                maxWidth: 800
                maxHeight: 600
                resizingBehavior: FILL
              ) {
                srcSet
                aspectRatio
                src
                sizes
                base64
              }
              id
              title
              description
            }
            usps {
              icon {
                file {
                  url
                }
              }
              label
            }
          }
        }
      }
    }
  `);

  const services = data.allContentfulService.edges.map(edge => ({
    key: edge.node.id,
    name: edge.node.name,
    title: edge.node.title,
    slug: edge.node.fields.slug,
    description: edge.node.description.description,
    gallery: edge.node.gallery ? edge.node.gallery : null,
    usps: edge.node.usps.map(({ icon, label }) => ({
      iconUrl: icon.file.url,
      label,
    })),
  }));

  return services;
};

export default servicesHook;
